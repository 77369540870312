@import url("https://use.typekit.net/kbw3ghx.css");
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url("https://use.typekit.net/kbw3ghx.css");


:root{
    --shiftwork:#1eaa4a;
    --shiftwork-highlight:#29862c;
    --launch-colour2:#f5f5f7;
    --launch-colour2-highlight:#eeeeee;
    --text-colour:#4c4c4c;
    --light-text-colour:#7d7d7d;
}
html{
    background: var(--shiftwork);

}
body{
    background: var(--shiftwork);
    margin: none;
    padding: none;
    z-index: -2;
}


.shiftwork-sub{
    height: 250px;
    width: 85%;
    padding-left: 7.5%;
    padding-right: 7.5%;
    background: var(--shiftwork);
}

/* font-family: "bebas-neue-pro", sans-serif; */
/* font-family: "podium-sharp-variable", sans-serif; */

.shiftwork-sub-inner{
    height: auto;
    padding-top: 50px;
    padding-bottom: 30px;
    width: 75%;
    background: white;
    margin: auto;
    border-radius: 45px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}

/* font-family: "Bebas Neue", sans-serif; */
.shiftwork-sub-title{
    font-size: 32.5px;
    margin-top: -20px;
    padding-bottom: 5px;
    color: var(--shiftwork);

    /* font-family: "Bebas Neue", sans-serif;
    font-weight: 700;
    font-style: italic; */
    font-style: italic;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 120, 'wght' 750;
}

.shiftwork-sub-subtitle{
    font-size: 18px;
    font-weight: 600;
    /* color: #4c4c4c; */
    color: var(--light-text-colour);
    font-family: "Inter", sans-serif;
    padding-bottom: 15px;
}


.shiftwork-sub-input-holder{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding-top: 5px;
    
}

.shiftwork-sub-input{
    height: 45px;
    border-radius: 15px;
    width: 300px;
    border: 2px solid #c4c4c4;
    text-indent: 20px;
    color: var(--light-text-colour);
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: italic;
}


.shiftwork-sub-btn{
    background: none;
    transition: 250ms ease-in-out;
    cursor: pointer;
    font-size: 20px;
}


.shiftwork-nav-mobile{
    height: 110px;
    /* width: calc(100% - 30px); */
    /* margin-left: 15px; */
    /* background: red; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-content: center;
    margin: auto;
    position: fixed;    
    z-index: 100;
    background: linear-gradient(to bottom, white, transparent );
}

.shiftwork-nav-mobile.scrolled{
    height: 80px;
    /* width: calc(100% - 30px); */
    background:none;
    margin: auto;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    position: fixed;    

}

.shiftwork-nav-inner-mobile{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.shiftwork-nav-inner-mobile.scrolled{
    height: auto;
    width: auto;
    display: flex;
    background-color: rgba(255, 255, 255, .85);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(15px);
    transition: background-color .35s;
    border-radius: 20px;
    padding-left: 5px;
    padding-right: 5px;
}


.shiftwork-nav-logo-mobile{
    /* height: 55px; */
    height: auto;
    width: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 10px;
    /* padding-right: 19px; */
    color: var(--shiftwork);
    /* border-radius: 20px; */
    /* font-family: "Bebas Neue", sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 70px;
    text-decoration: none; */
    font-size: 40px;
    font-style: italic;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 125, 'wght' 844;


    text-align: left;
    /* padding-top: 3px; */
    /* background: blue; */
    flex-direction: column;
    cursor: pointer;
}
.shiftwork-nav-logo-mobile.scrolled{
    height: auto;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    /* padding-right: 19px; */
    color: var(--shiftwork);
    border-radius: 20px;
    margin-top: 5px;
    /* font-family: "Bebas Neue", sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 50px; */
    font-size: 30px;
    /* padding-top: 4px; */
    text-decoration: none;
}


.shiftwork-nav-subtitle-mobile{
    font-size: 15px;
    /* margin-top: -20px; */
    color: var(--shiftwork-highlight);
    font-style: italic;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 120, 'wght' 750;

}


.shiftwork-nav-tray-mobile{
    height: 50px;
    width: 50px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: var(--light-text-colour);
    /* background: red; */
    background-color: rgba(255, 255, 255, .85);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(15px);
    transition: background-color .35s;
    margin-right: 10px;
    cursor: pointer;
}

.shiftwork-nav-tray-mobile.scrolled{
    height: 58px;
    width: 58px;
    background: none;
    backdrop-filter: none;
    margin-right: 0px;
    border: none;
}


.shiftwork-modal{
    height: auto;
    padding-top: 50px;
    /* padding-bottom: 25px; */
    width: 100vw;
    background-color: rgba(255, 255, 255, .85);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(15px);
    transition: background-color .35s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.shiftwork-logo-modal{
    /* height: 55px; */
    /* background: blue; */
    /* padding-top: 25px; */
    height: auto;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-right: 19px; */
    color: var(--shiftwork);
    font-size: 50px;
    font-style: italic;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 120, 'wght' 850;
    /* margin-bottom: 42.5px; */
}

.shiftwork-modal-btn-holder{
    width: 100%;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 25px;
    gap: 10px;
    
    flex-direction: column;
}

.shiftwork-modal-btn{
    height: 50px;
    width: 90%;
    background: none;
    border: none;

    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 100, 'wght' 650;

    font-style: italic;
    font-size: 35px;

    color: var(--light-text-colour);

    
}

.shiftwork-modal-btn-start{
    height: 65px;
    width: 90%;
    background: none;
    border: none;
    margin-top: 25px;

    font-style: italic;
    font-size: 42.5px;

    color: var(--text-colour);
    color: var(--light-text-colour);

    
}

.shiftwork-close-modal-btn-holder{
    height: 105px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shiftwork-close-modal-btn{
    height: 60px;
    width: 60px;
    border-radius: 100%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, .85);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(15px);
    transition: background-color .35s;
    color: var(--light-text-colour);
    cursor: pointer;
}





.shiftwork-nav-desktop {
    z-index: 100;
    position: fixed;
    width: calc(100% - 50px);
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.25s ease-in-out;
    padding-top: 15px;
    background: linear-gradient(to bottom, white, transparent );
    /* background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.4) 60%, rgba(255, 255, 255, 0.3) 70%, rgba(255, 255, 255, 0.2) 80%, rgba(255, 255, 255, .2) 90%, rgba(255, 255, 255, 0) 100%); */
}

.shiftwork-nav-desktop.scrolled {
    height: 70px;
    /* background: white; */
    padding-top: 0px;
    background-color: rgba(255, 255, 255, .85);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(15px);
    transition: background-color .35s;
}

.shiftwork-nav-title {
    height: auto;
    width: auto;
    padding-bottom: 25px;
    transition: all 0.25s ease-in-out;
}

.shiftwork-nav-subtitle {
    transition: all 0.25s ease-in-out;
    color: var(--shiftwork-highlight);
    /* letter-spacing: 2px;
    font-family: "Bebas Neue", sans-serif;
    font-size: 45px;
    font-weight: 600;
    font-style: italic; */
    /* padding-top: 5px; */

    font-size: 32px;
    font-style: italic;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 120, 'wght' 750;
      
    /* font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 125, 'wght' 844; */

    /* background: blue; */
    transition: all 250ms ease-in-out;
}

.shiftwork-nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 50px;
    transition: all 0.25s ease-in-out;
}

#menu-scrolled-large{
    padding-top: 30px;
}

.nav-option {
    font-size: 16.5px;
    background: none;
    border: none;
    font-family: "Inter", sans-serif;
    color: var(--shiftwork);
    font-weight: 500;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-option:hover{
    text-decoration: 2px solid underline;
    text-underline-offset: 7px;
}

#shiftwork-button {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 10px;
    width: auto;
    color: var(--shiftwork);
    border: 2px solid var(--shiftwork);
    border-radius: 15px;
    
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 80, 'wght' 800;
      
    /* font-family: "Bebas Neue", sans-serif; */
    /* font-family: "podium-sharp-variable",sans-serif; */
    /* font-variation-settings: 'wdth' 120, 'wght' 750; */
    /* font-size: 22.5px; */

    text-decoration: none;
}

#shiftwork-button:hover {
    background: var(--shiftwork);
    border: 2px solid var(--shiftwork-highlight);
    box-shadow: 3px 4px 0px 0px var(--shiftwork-highlight);
    color: white;

    font-style: italic;
}

#shiftwork-button:active {
    background: var(--shiftwork-highlight);
}

.shiftwork-text-logo-nav:hover {
    color: var(--shiftwork-highlight);
}
.shiftwork-text-logo-nav {
    color: var(--shiftwork);
    /* letter-spacing: 2px;
    font-family: "Bebas Neue", sans-serif;
    font-size: 150px;
    font-style: italic;
    font-weight: 700; */
    height: 90px;
    font-size: 90px;
    font-style: italic;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 125, 'wght' 844;
    /* background: red; */
    /* height: 139px; */
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.shiftwork-text-logo-footer {
    color: var(--shiftwork);

    /* font-family: "Bebas Neue", sans-serif;
    font-style: italic;
    font-weight: 700; */
    /* background-color: red; */
    margin-top: 5px;
    font-size: 55px;
    font-style: italic;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 125, 'wght' 844;

    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
.shiftwork-text-logo-footer:hover {
    color: var(--launch-colour2);
}

.shiftwork-text-logo-nav.logo-scrolled:hover {
    color: var(--shiftwork-highlight);
}
.shiftwork-text-logo-nav.logo-scrolled {
    font-size: 45px; /* Shrink logo */
    height: 60px;
    margin-top: 40px;
    /* background: red;  */
    /* padding-top: 40px; */
}































.shiftwork-main{
    height: 89vh;
    /* background: var(--launch-colour2); */
    display: flex;
    align-items: center;
    justify-content: center;
}

.shiftwork-background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }




.shiftwork-main-action-holder{
    height: 60px;
    width: 800px;
    border-radius: 20px;
    
    background-color: rgba(255, 255, 255, .85);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(15px);
    transition: background-color .35s;
    
    margin-top: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.1);
    transition: 250ms ease-in-out;
}

.shiftwork-main-action-holder:hover{
    /* border: 2px solid var(--shiftwork); */
    /* box-shadow: 3px 4px 0px 0px var(--shiftwork-highlight); */
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}

.shiftwork-main-action-left{
    height: calc(100% - 10px);
    margin-left: 5px;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 5px;
}


.shiftwork-main-action-btn{
    height: 100%;
    min-width: 190px;
    width: auto;
    padding-left: 10px;
    padding-right: 20px;
    border: none;
    background: none;
    border-radius: 16px;
    transition: 200ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    font-family: "Inter", sans-serif;
}


.shiftwork-main-action-btn:hover{
    background: var(--launch-colour2);
}
.shiftwork-main-action-btn:active{
    background: var(--launch-colour2-highlight);
    /* background: red; */
}


.shiftwork-main-action-btn-title{
    height: 10px;
    padding-top: 5px;
    width: calc(100%);
    /* background: red; */
    font-size: 11px;
    /* padding-left: 10px; */
    display: flex;
    align-items: center;
    font-weight: 600;
    /* color: var(--text-colour); */
    color: #4c4c4c;
}

.shiftwork-main-action-btn-value{
    /* height: calc(100% - 12.5px); */
    width: calc(100% - 10px);
    padding-left: 10px;
    display: flex;
    align-items:center;
    font-size: 22px;
    font-weight: 700;
    color: var(--text-colour);
    /* background: red; */
}







.shiftwork-main-action-right{
    height: calc(100% - 10px);
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}



.shiftwork-main-action-btn-search{
    height: 100%;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    cursor: pointer;

    font-size: 22px;
    /* font-style: italic; */
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 125, 'wght' 844;
    padding-left: 12.5px;
    padding-right: 20px;
    
    transition: 200ms ease-in-out;
    color: var(--shiftwork);
    border: 2px solid var(--shiftwork);
}

.shiftwork-main-action-btn-search:hover{
    background: var(--shiftwork);
    color: white;
    border: 2px solid var(--shiftwork-highlight);
    font-style: italic;
}

.shiftwork-main-action-btn-search:active{
    background: var(--shiftwork-highlight);
    color: white;
    border: 2px solid var(--shiftwork-highlight);
}




.shiftwork-partners{
    height: auto;
    width: 100%;
    z-index: 1;
    background: white;
    padding-top: 35px;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
}

.shiftwork-partners-title{
    font-size: 20px;
    font-weight: 600;
    /* color: #4c4c4c; */
    color: var(--light-text-colour);
    font-family: "Inter", sans-serif;
    padding-bottom: 15px;
}


.shiftwork-partners-firms{
    height: 100px;
    /* background: blue; */
    width: auto;
    max-width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.shiftwork-firm{
    height: 75px;
    width: 150px;
    /* background: var(--launch-colour2); */
    opacity: 0.3;
    transition: 250ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.shiftwork-firm img{
    width: 72.5%;
    height: auto;
    object-fit: contain;      
    object-position: center;
    filter: grayscale();
}
.shiftwork-firm:hover{
    opacity: 0.45;
}


.industries-container{
    height: auto;
    width: 100%;
    background: var(--shiftwork);
    padding-bottom: 50px;
    padding-top: 25px;
}

.industries-title{
    height: 100px;
    width: 100%;
    /* background: blue; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 50px;
    text-align: center;
    color: white;
    font-size: 90px;
    /* font-weight: 700;
    font-family: "Inter", sans-serif;
    font-family: "Bebas Neue", sans-serif; */
    font-style: italic;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 120, 'wght' 800;
}


.industries-switch-holder{
    height: 100px;
    width: 920px;
    background: white;
    border-radius: 30px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

#industries-switch-active{
    opacity: 1;
    background: var(--launch-colour2);
}

.industries-switch{
    height: 85px;
    width: 120px;
    border-radius: 25px;
    background: white;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--shiftwork);
    /* font-family: "Inter", sans-serif;
    font-family: "Bebas Neue", sans-serif;
    font-weight: 600; */
    font-style: italic;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 70, 'wght' 750;
    font-size: 19px;
    cursor: pointer;
    opacity: 0.5;
    transition: 200ms ease-in-out;
}
.industries-switch:hover{
    opacity: 1;
    background: var(--launch-colour2);
}
.industries-switch:active{
    background: var(--launch-colour2-highlight);
}

.industries-info-container{
    height: 600px;
    width: 80%;
    margin: auto;
    background: white;
    border-radius: 30px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.industries-info-image{
    height: 100%;
    width: 40%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.industries-info-image-inner{
    background: var(--launch-colour2);
    height: calc(100% - 40px);
    width: calc(100% - 40px);
    border-radius: 20px;
    overflow: hidden;
}

.industries-info-image-inner img {
    width: 100%;            
    height: 100%;           
    object-fit: cover;      
    object-position: center;
}

.industries-info-text{
    height: 100%;
    width: calc(60% - 25px);
    padding-left: 25px;
    background:white;
    display: flex;
    align-items: flex-start;
    margin-top: 150px;
    /* justify-content: center; */
    flex-direction: column;
}

.industries-info-text-title{
    /* background: blue; */
    /* height: 80px; */
    margin-top: 15px;
    margin-bottom: -5px;
    width: 100%;
    font-size: 75px;
    display: flex;
    align-items: center;
    /* font-family: "Inter", sans-serif; */
    /* font-weight: 700;
    font-family: "Bebas Neue", sans-serif; */
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 100, 'wght' 800;
    font-style: italic;

    color: var(--text-colour);
}

.industries-info-text-subtitle{
    /* background: green; */
    /* height: 80px; */
    /* color: var(--text-colour); */
    color: var(--light-text-colour);
    width: 100%;
    font-size: 35px;
    display: flex;
    align-items: center;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 100, 'wght' 700;
    /* font-weight: 700;
    font-family: "Bebas Neue", sans-serif; */
    font-style: italic;
}

.industries-info-text-text{
    color: var(--text-colour);
    width: 90%;
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    /* height: 300px; */
    padding-top: 25px;
    padding-bottom: 40px;
    line-height: 21px;

    /* background: blue; */
}

.industries-info-text-btn{
    background: white;
    transition: 200ms ease-in-out;

    font-size: 25px;
}




.shiftwork-section-five{
    height: auto;
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    padding-top: 150px;
    padding-bottom: 100px;
    flex-direction: row;
    align-items: center;
    background: white;
}

.shiftwork-section-five-arrow:hover{
    color: var(--text-colour);
}
.shiftwork-section-five-arrow{
    height: 50px;
    width: 25px;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: var(--light-text-colour);
    transition: 200ms ease-in-out;
    cursor: pointer;
}

.shiftwork-section-five-info-holder{
    height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.shiftwork-section-five-quote{
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 45px;
    color: var(--text-colour);
    width: 70%;
    height: auto;
    text-align: center;
}

.shiftwork-section-five-icon{
    height: 80px;
    width: 80px;
    border-radius: 15px;
    background: var(--launch-colour2);
    margin-top:30px ;
    margin-bottom:30px ;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-size: 45px;
}

.shiftwork-section-five-name{
    font-family: "Inter", sans-serif;
    font-weight: 700;
    color: var(--text-colour);
    font-size: 20px;
}

.shiftwork-section-five-title{
    font-family: "Inter", sans-serif;
    font-weight: 600;
    margin-top: 5px;
    color: var(--light-text-colour);
}

.shiftwork-section-five-info-holder .fade {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.shiftwork-section-five-info-holder .fade:not(.fade) {
    opacity: 1;
}







.shiftwork-footer{
    height: auto;
    width: calc(100% - 50px);
    padding-left: 25px;
    padding-right: 25px;
    background: var(--shiftwork);
    display: flex;
    padding-top: 20px;
    flex-direction: column;
}

.shiftwork-footer-top{
    height: 250px; 
    padding-top: 25px;
    width: 100%;
    /* background: red; */
    display: flex;
    gap: 25px;
}

.shiftwork-footer-top-section{
    height: 100%;
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.shiftwork-footer-top-section-title{
    font-size: 45px;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    color: white;
    padding-bottom: 10px;
}

.shiftwork-footer-top-section-value:hover{
    text-decoration:solid 2px underline;
    text-underline-offset: 4px;
}
.shiftwork-footer-top-section-value{
    font-size: 20px;
    height: 30px;
    width: auto;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    color: white;
    border: none;
    background: none;
    padding-left: 10px;
    cursor: pointer;
    /* text-indent: 10px; */
}






.shiftwork-footer-bottom{
    height: 139px;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.shiftwork-footer-bottom-right {
    height: 100%;
    /* min-width: 750px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7.5px;
    margin-top: -5px;
}

.shiftwork-footer-bottom-right-row{
    width: 100%;
    height: 40px;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 7.5px;
}


.shiftwork-social{
    height: 40px;
    padding-left: 25px;
    padding-right: 25px;
    border: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12.5px;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    /* background: white; */
    background: none;
    font-size: 14px;
    background: white;
    color: var(--shiftwork);
    cursor: pointer;
    font-style: italic;
    transition: 200ms ease-in-out;
}
.shiftwork-social:hover{
    /* background: var(--launch-colour2); */
    /* background: white; */
    /* color: var(--shiftwork-highlight); */
    text-decoration:solid 2px underline;
    text-underline-offset: 4px;
}




.shiftwork-faq-container{
    height: auto;
    width: 100%;
    background: white;
    display: flex;
    align-items: center;
    gap: 25px;
    flex-direction: column;
    padding-bottom: 50px;
    padding-top: 25px;
}



.shiftwork-faq-title:hover{
    color: var(--shiftwork-highlight);
}
.shiftwork-faq-title{
    height: AUTO;
    width: 100%;
    /* background: blue; */
    padding-top: 50px;
    /* padding-bottom: 50px; */
    text-align: center;
    font-size: 95px;

    font-style: italic;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 100, 'wght' 800;

    /* color: var(--text-colour); */
    color: var(--shiftwork);

    transition: 200ms ease-in-out;
}

.shiftwork-faq-subtitle{
    font-size: 20px;
    font-weight: 700;
    color: var(--light-text-colour);
    font-family: "Inter", sans-serif;
    margin-top: -10px;
    padding-bottom: 25px;
}


.shiftwork-faq-inner-container{
    height: 225px;
    width: 80%;
    background: var(--launch-colour2);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.shiftwork-faq-inner-container-img img{
    width: 100%;            
    height: 100%;           
    object-fit: cover;      
    object-position: center;
}

.shiftwork-faq-inner-container-img{
    height: calc(100% - 20px);
    margin-left: 10px;
    width: 40%;
    border-radius: 21px;
    background: var(--launch-colour2-highlight);
    overflow: hidden;
}


.shiftwork-faq-inner-container-txt{
    height: calc(100% - 20px);
    margin-right: 10px;
    width: calc(60% - 50px);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    /* background: white; */
}

.shiftwork-faq-inner-container-txt-q{
    width: 100%;
    font-size: 30px;
    display: flex;
    align-items: center;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 100, 'wght' 800;
    /* font-weight: 700;
    font-family: "Bebas Neue", sans-serif;
     */
    font-style: italic;
    color: var(--text-colour);
    padding-bottom: 5px;
}

.shiftwork-faq-inner-container-txt-a{
    padding-bottom: 20px;
    max-width: 85%;
    font-family: "Inter", sans-serif;
    /* color: var(--text-colour); */
    color: var(--light-text-colour);
    font-weight: 600;
}

.shiftwork-faq-inner-container-txt-btn{
    transition: 200ms ease-in-out;
    cursor: pointer;
    background: none;
}



.shiftwork-about-holder{
    min-height: calc(100vh - 60px);
    height: auto;
    width: 80%;
    padding-top: 225px;
    padding-bottom: 60px;
    margin: auto;
}


.shiftwork-about-mission-holder{
    height: auto;
    /* background: red; */
    padding-top: 75px;
    /* padding-bottom: 67px; */
    width: 100%;
    /* height: 300px; */
}


.shiftwork-about-mission-statement{
    font-size: 100px;
    font-weight: 700;
    display: flex;
    align-items: center;

    /* font-family: "Bebas Neue", sans-serif;
    font-style: italic; */
    
    font-style: italic;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 120, 'wght' 750;

    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.shiftwork-mission-statement{

    line-height: 135px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 21px;
    color: var(--shiftwork);
}

.ms-word1, .ms-word2, .ms-word3, .ms-word4, .ms-word5, .ms-word6, .ms-word7, .ms-word8 {
    color: var(--shiftwork);
    transition: color 0.5s ease;
}

.ms-word1.animate { transition-delay: 0s; color: white; }
.ms-word2.animate { transition-delay: 0.25s; color: white; }
.ms-word3.animate { transition-delay: 0.50s; color: white; }
.ms-word4.animate { transition-delay: 0.75s; color: white; }
.ms-word5.animate { transition-delay: 1.0s; color: white; }
.ms-word6.animate { transition-delay: 1.25s; color: white; }
.ms-word7.animate { transition-delay: 1.50s; color: white; }
.ms-word8.animate { transition-delay: 1.75s; color: white; }




.shiftwork-about-mission-card.animate {
    opacity: 1;
    transform: translateY(0);
}

/* Optionally stagger the animations for each card */
.shiftwork-about-mission-card:nth-child(1).animate {
    transition-delay: 1.25s;
}
.shiftwork-about-mission-card:nth-child(2).animate {
    transition-delay: 1.50s;
}
.shiftwork-about-mission-card:nth-child(3).animate {
    transition-delay: 1.75s;
}

.shiftwork-about-mission-bottom{
    /* height: calc(100% - 469px); */
    /* height: calc(100% - 400px); */
    height: auto;
    width: 100%;
    margin: auto;
    padding-top: 50px;
    /* padding-bottom: 50px; */
    /* background: red; */
    margin-top: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.shiftwork-about-mission-card{
    height: 100%;
    width: calc(100% / 3);
    background: white;
    border-radius: 50px;
    flex-direction: column;
    opacity: 0;
    transform: translateY(20px); /* Optional: to create a subtle upward movement during fade-in */
    transition: opacity 0.5s ease, transform 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}


.shiftwork-about-mission-card-img{
    height: 300px;
    background: var(--launch-colour2);
    margin-top: 10px;
    width: calc(100% - 20px);
    border-radius: 42.5px;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.shiftwork-about-mission-card-img img{
    width: 100%;            
    height: 100%;           
    object-fit: cover;      
    object-position: center;
}


.shiftwork-about-mission-card-title{
    height: 35px;
    width: calc(100% - 20px);
    /* background: red; */
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    /* font-family: "Bebas Neue", sans-serif;
    font-weight: 800;
    font-style: italic; */
    font-size: 30px;
    font-style: italic;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 120, 'wght' 750;

    margin-top: 20px;
    /* color: var(--light-text-colour); */
    color: var(--text-colour);
    /* font-family: "Inter", sans-serif; */
    
}

.shiftwork-about-mission-card-text{
    /* margin-top: -5px; */
    height: 75px;
    width: calc(100% - 40px);
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom-left-radius: 42.5px;
    overflow: hidden;
    border-bottom-right-radius: 42.5px;
    text-align: center;
    color: var(--light-text-colour);
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.5px;
    padding-bottom: 10px;
}

#title-top{
    border-top-left-radius: 42.5px;
    border-top-right-radius: 42.5px;
}

#text-top{
    border-radius: 0px;
}












.shiftwork-trust-holder{
    height: auto;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 87px;
}

.shiftwork-trust-card-holder{
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    flex-wrap: wrap;
}

.shiftwork-trust-card:hover{
    transform: scale(1.03);
}

.shiftwork-trust-card{
    height: 450px;
    width: calc((100% / 3) - 17px);
    background: var(--launch-colour2);
    border-radius: 25px;
    transition: 250ms ease-in-out;
}







.shiftwork-trust-factors-v2{
    /* height: 700px; */
    height:auto;
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
    /* background: var(--launch-colour2); */
    background: white;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: center;
    gap: 100px;
}


.shiftwork-trust-factors-v2-title{
    padding-top: 85px;
    /* background: red; */
    /* padding-bottom: 65px; */
    margin-bottom: -20px;
    text-align: center;
    font-weight: 700;
    color: var(--shiftwork);
    font-style: italic;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 125, 'wght' 800;
    font-size: 80px;
    /* font-size: 95px;
    font-family: "Inter", sans-serif;
    font-family: "Bebas Neue", sans-serif;
    font-style: italic;
    letter-spacing: 2px; */
    /* height: 100px; */
    /* background: blue; */
}


.shiftwork-trust-factors-v2-lower{
    height: 400px;
    width: 100%;
    display: flex;
}

.shiftwork-trust-factors-v2-left{
    height: 100%;
    width: 45%;
    /* background: red; */
}

.shiftwork-trust-factors-v2-left-inner{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.shiftwork-trust-factors-v2-left-switch-holder{
    height: 65px;
    padding: 10px;
    width: auto;
    /* min-width: 400px; */
    /* background:var(--launch-colour2); */
    border-radius: 25px;
    display: inline-flex;
    gap: 10px;
}

.shiftwork-trust-factors-v2-left-switch:hover{
    background: var(--launch-colour2-highlight);
}

.shiftwork-trust-factors-v2-left-switch{
    height: 65px;
    width: 70px;
    border: none;
    border-radius: 20px;
    opacity: 0.35;
    background: var(--launch-colour2);
    cursor: pointer;
    font-size: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#trust-factor-active{
    opacity: 1;
}

.shiftwork-trust-factors-v2-left-title{
    width: 100%;
    font-size: 45px;
    /* font-weight: 700;
    font-family: "Bebas Neue", sans-serif;
    font-style: italic; */
    font-style: italic;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 125, 'wght' 844;    
    display: flex;
    align-items: center;
    /* color: var(--light-text-colour); */
    margin-bottom: -0px;
    color: var(--text-colour);
    transition: 800ms ease-in-out;
}

.shiftwork-trust-factors-v2-left-text{
    height: auto;
    padding-bottom: 20px;
    width: calc(100% - 50px);
    color: var(--text-colour);
    font-size: 18px;
    font-weight: 500;
    display: flex;
    /* align-items: center; */
    font-family: "Inter", sans-serif;
    padding-top: 10px;
    /* margin-bottom: -15px; */
    transition: all 800ms ease-in-out;
}

.shiftwork-trust-factors-v2-left-btn{
    background: none;
    cursor: pointer;
    transition: 200ms ease-in-out;
    font-size: 20px;
}

.shiftwork-trust-factors-v2-right{
    height: 100%;
    width: 55%;
    overflow: hidden;
    /* background: var(--launch-colour2); */
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 800ms ease-in-out;
    /* background: red; */
}
.shiftwork-trust-factors-v2-right img{
    width: 100%;            
    height: 100%;           
    object-fit: cover;      
    object-position: center;
}

.fade-slide {
    opacity: 0;
    transform: translateX(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.shiftwork-trust-factors-v2-right-container .fade-slide {
    opacity: 1;
    transform: translateX(0);
}


.fade-slide {
    opacity: 0;
    transform: translateX(20px);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.shiftwork-trust-factors-v2-left-title.fade-slide,
.shiftwork-trust-factors-v2-left-text.fade-slide {
    opacity: 1;
    transform: translateX(0);
}











.shiftwork-trust-factors-v3{
    /* height: 700px; */
    height:auto;
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
    /* background: var(--launch-colour2); */
    background: white;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: center;
    padding-top: 75px;
}


.shiftwork-trust-factors-v3-title{
    padding-top: 35px;
    padding-bottom: 65px;
    text-align: center;
    font-size: 95px;
    font-weight: 700;
    color: var(--text-colour);
    font-family: "Inter", sans-serif;
    font-family: "Bebas Neue", sans-serif;
    font-style: italic;

    /* height: 100px; */
    /* background: blue; */
}


.shiftwork-trust-factors-v3-lower{
    height: 400px;
    width: 100%;
    display: flex;
    margin-bottom: 150px;
}

.shiftwork-trust-factors-v3-left{
    height: 100%;
    width: 45%;
    /* background: red; */
}

.shiftwork-trust-factors-v3-left-inner{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}
#trust-right{
    align-items: flex-end;
}

.shiftwork-trust-factors-v3-left-switch-holder{
    height: 65px;
    padding: 10px;
    width: auto;
    /* min-width: 400px; */
    /* background:var(--launch-colour2); */
    border-radius: 25px;
    display: inline-flex;
    gap: 10px;
}

.shiftwork-trust-factors-v3-left-switch:hover{
    background: var(--launch-colour2-highlight);
}

.shiftwork-trust-factors-v3-left-switch{
    height: 65px;
    width: 70px;
    border: none;
    border-radius: 20px;
    opacity: 0.35;
    background: var(--launch-colour2);
    cursor: pointer;
    font-size: 35px;
}

#trust-factor-active{
    opacity: 1;
}

.shiftwork-trust-factors-v3-left-title{
    /* width: 100%; */
    font-size: 65px;
    font-weight: 700;
    display: flex;
    align-items: center;
    font-family: "Bebas Neue", sans-serif;
    font-style: italic;
    /* color: var(--light-text-colour); */
    margin-bottom: -10px;
    color: var(--text-colour);
}

.shiftwork-trust-factors-v3-left-text{
    height: 125px;
    width: calc(100% - 50px);
    color: var(--text-colour);
    font-size: 18px;
    font-weight: 500;
    display: flex;
    /* align-items: center; */
    font-family: "Inter", sans-serif;
    padding-top: 10px;
    margin-bottom: -15px;
}

#trust-right-text{
    text-align: right;
    margin-right:-25px;
}

.shiftwork-trust-factors-v3-left-btn{
    background: none;
    cursor: pointer;
    transition: 200ms ease-in-out;
}

.shiftwork-trust-factors-v3-right{
    height: 100%;
    width: 55%;
    background: var(--launch-colour2);
    border-radius: 30px;
    /* background: red; */
}


.fade {
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
}

.fade:not(.fade) {
    opacity: 1;
}



.shiftwork-signup-container{
    height: calc(100vh - 150px);
    background: white;
    padding-top: 100px;
    padding-bottom: 25px;
}

.shiftwork-signup-title{
    /* padding-top: 35px; */
    padding-bottom: 25px;
    text-align: center;
    font-size: 95px;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 125, 'wght' 844;
    color: var(--text-colour);

    font-style: italic;

    /* height: 100px; */
    /* background: blue; */
}

.shiftwork-signup-switch-container{
    height: 50px;
    width: 450px;
    padding: 5px;
    background: var(--shiftwork);
    margin: auto;
    border-radius: 25px;
    margin-top: -2.5px;
    display: flex;
}

.shiftwork-signup-switch{
    height: 50px;
    width: 225px;
    border-radius: 20px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    padding-top: 5px;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 120, 'wght' 750;
    font-size: 20px;
    
}

#signup-switch-active{
    background: white;
    color: var(--shiftwork);
    display: flex;
    align-items: center;
    justify-content: center;
}

.shiftwork-signup-inner-container{
    height: auto;
    width: calc(70% - 20px);
    padding: 10px;
    padding-top: 30px;
    padding-bottom: 50px;
    background: var(--shiftwork);
    background: var(--launch-colour2);
    margin: auto;
    margin-top: 25px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shiftwork-signup-inner-left{
    height: 100%;
    width: 40%;
    background: white;
    border-radius: 45px;
}
.shiftwork-signup-inner-right{
    height:100%;
    /* background: red; */
    width: calc(100% - 50px);
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.shiftwork-signup-inner-right-title{
    font-size: 40px;

    display: flex;
    align-items: center;

    font-style: italic;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 120, 'wght' 850;

    /* color: var(--light-text-colour); */
    margin-bottom: -5px;
    color: var(--text-colour);
    
}

.shiftwork-signup-inner-right-subtitle{
    font-size: 25px;
    display: flex;
    /* font-weight: 700;
    align-items: center;
    font-family: "Bebas Neue", sans-serif; */
    font-style: italic;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 100, 'wght' 700;

    font-style: italic;
    color: var(--light-text-colour);
    margin-bottom: 10px;
    margin-top: 7.5px;
}

.shiftwork-signup-inner-right-input-holder {
    /* height: calc(100% - 300px); */
    margin-bottom: 20px;
    /* background: white; */
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    grid-template-rows: repeat(2, auto);   /* 2 rows */
    gap: 10px;                              /* Space between grid items */
    padding-top: 20px;
    padding-bottom: 20px;

}

.input-field {
    grid-column: span 1;                   /* Each input spans one column */
    width: 100%;                           /* Ensure input fields take full width */
    padding: 10px;                         /* Add padding for better spacing */
    border: 1px solid #ccc;                /* Optional border styling */
    border-radius: 4px;                    /* Rounded corners for inputs */
    box-sizing: border-box;                 /* Include padding and border in width calculation */
    border-radius: 15px;
    height: 60px;
    text-indent: 10px;
    color: var(--light-text-colour);
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: italic;
}

select.input-field {
    grid-column: span 3;                   /* Make the dropdown span all 3 columns */
}

.shiftwork-signup-inner-right-btn{
    width: 100%;
    background: none;
    transition: 200ms ease-in-out;
    cursor: pointer;
    font-size: 20px
}

.shiftwork-specials-card-holder{
    height: 400px;
    width: 100%;
    /* background: blue; */
    justify-content: flex-start; /* Changed to flex-start for horizontal scrolling */
    gap: 10px;
    display: flex;
    align-items: center;
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Hide vertical scrolling if not needed */
    scroll-snap-type: x mandatory; /* Optional: for smooth scrolling effects */
}
.shiftwork-specials-card{
    height: 100%;
    width: 75%; /* Adjust width as needed */
    /* min-width: 325px; */
    background: var(--launch-colour2);
    border-radius: 25px;
    flex: 0 0 auto; /* Prevents cards from shrinking */
    scroll-snap-align: start; /* Optional: aligns the cards during scroll */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
}

.shiftwork-specials-card-quote{
    height: auto;
    width: 100%;
    /* background: red; */
    text-align: center;
    font-size: 25px;
    width: 100%;
    font-family: "Inter", sans-serif;
    color: var(--text-colour);
    font-weight: 700;
}

.shiftwork-specials-card-icon{
    height: 58px;
    width: 60px;
    background: white;
    border-radius: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
}


.shiftwork-specials-card-name{
    font-family: "Inter", sans-serif;
    font-weight: 700;
    color: var(--text-colour);
    font-size: 20px;
    margin-bottom: 5px;
}

.shiftwork-specials-card-role{
    font-family: "Inter", sans-serif;
    font-weight: 600;
    margin-top: 5px;
    color: var(--light-text-colour);
}



/* (min-width: 768px) and (max-width: 1024px) */

/* iPad MINI (768px width) */
@media (max-width: 768px) {
    .shiftwork-trust-factors-v2-left-title,.shiftwork-trust-factors-v2-left-title.fade-slide{}
    .shiftwork-trust-factors-v2-left-text{padding-left: 10px;}
    .ms-word1, .ms-word2, .ms-word3, .ms-word4, .ms-word5, .ms-word6, .ms-word7, .ms-word8 {

        overflow-x: visible;
    }
    .shiftwork-nav-menu{
        gap: 0px;
    }

    .industries-info-text-subtitle{
        font-size: 25px;

    }
    body{
        overflow-x: hidden;
    }
    .shiftwork-trust-factors-v2-left-title{
        padding-top: 25px;
        font-variation-settings: 'wdth' 120, 'wght' 700;
    }
    .shiftwork-main-action-holder{
        flex-direction: column;
        height: auto;
        width: 60%;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: rgba(255, 255, 255, .65);
    }
    .shiftwork-main-action-left{
        flex-direction: column;
        gap: 10px;
        width: 100%;
        margin-left: 0px;
        padding-bottom: 10px;
        gap: 0px;
    }
    .shiftwork-main-action-btn{
        width: calc(100% - 10px);
        padding-left: 0px;
        margin-left: 0px;
        border-radius: 5px;
        padding-left: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .shiftwork-main-action-btn-value{
        font-size: 18px;
    }
    .shiftwork-main-action-right{
        width: 90%;
        padding-bottom: 10px;
    }
    .shiftwork-main-action-btn-search{
        width: 90%;
        padding-top: 5px;
        padding-bottom: 5px;
    }


    .shiftwork-partners-firms {
        padding-top: 20px;
        height: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px; /* Adjust the gap between grid items as needed */
    }


    .shiftwork-faq-inner-container{
        flex-direction: column;
        height: auto;
    }
    .shiftwork-faq-inner-container-txt{
        width: 100%;
    }
    .shiftwork-faq-inner-container-txt-a{
        width: calc(100% - 20px);
        margin-left: 25px;
        margin-top: 10px;
        max-width: 100%;
    }
    .shiftwork-faq-inner-container-txt-q{
        /* background: red; */
        width: calc(100% - 20px);
        margin-left: 20px;
        margin-top: 10px;

    }
    .shiftwork-faq-inner-container-txt-btn{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;
        width: 80%;
    }
    .shiftwork-faq-inner-container-img{
        height: 150px;
    }



    .shiftwork-faq-title{
        font-size: 55px;
        font-style: italic;

    }
    .shiftwork-signup-container{
        height: auto;
    }
    .shiftwork-signup-inner-left{
        border-radius: 20px;
    }
    .shiftwork-signup-inner-right-input-holder{
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        /* background: red; */
    }
    .industries-switch-holder{
        width: calc(95% - 20px);
        padding-left: 10px;
        padding-right: 10px;
        gap: 10px;
    }
    .shiftwork-signup-inner-container{
        width: calc(95% - 50px);
        flex-direction: column;
        padding-bottom: 20px;
        height: auto;
        border-radius: 25px;
    }
    .industries-info-container{width: 95%;}
    .industries-info-text{
        width: 60%;
        padding-left: 0px;
    }
    .industries-info-text-text{
        width: calc(100% - 10px);
        padding-bottom: 22.5px;
    }
    .shiftwork-main-action-btn{min-width: 100px;}
    .shiftwork-signup-inner-left{
        width: 100%;
        height: 250px;
    }
    .shiftwork-signup-inner-right{width: calc(95%);}
    .shiftwork-section-five{
        width: calc(100% - 50px);
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 100px;
    }
    .shiftwork-trust-factors-v2-lower{
        flex-direction: column-reverse;
        height: 800px;
    }
    .shiftwork-about-mission-bottom{flex-direction: column;}
    .shiftwork-about-mission-card{width: 100%;}
    .shiftwork-about-mission-card-title{padding-top: 15px;}
    .shiftwork-trust-factors-v2-right {width: 100%;}
    .shiftwork-trust-factors-v2-left {width: 100%;}
    .shiftwork-faq-inner-container{width: 95%;}
    .shiftwork-about-mission-bottom{gap: 25px;}
    .shiftwork-faq-title{height: auto; }
    .shiftwork-faq-inner-container-img{width: calc(100% - 20px);margin-left: 0px; margin-top: 10px;}
    .shiftwork-faq-inner-container-txt-q{font-size: 32px;}
    .shiftwork-footer-bottom{flex-direction: column;}
    .shiftwork-footer-bottom-right{padding-bottom: 50px;}
    .shiftwork-footer-bottom-right-row{justify-content: center;}

    .shiftwork-text-logo-footer{font-size: 50px; }
    .shiftwork-footer-bottom-right{height: auto; padding-top: 25px;}
    .shiftwork-footer-top-section{
        width: 100%;
        align-items: center;
    }
    .shiftwork-footer-top{
        flex-direction: column;
        height: auto;
        width: 100%;
        padding-bottom: 50px;
        align-items: center;
        justify-content: center;
    }
    .shiftwork-footer-bottom-right-row{
        flex-direction: column;
        height: auto;
    }
    .shiftwork-about-holder{
        width: 92.5%;
        padding-top: 400px;
    }
    .shiftwork-about-mission-bottom{
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
        /* background: red; */
    }
    .shiftwork-about-mission-card{
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .shiftwork-mission-statement{
        font-size: 55px;
        line-height:70px;
    }

    .shiftwork-trust-factors-v2{
        width: 95%;
        padding-left: 2.5%;
        padding-right: 2.5%;
        padding-top: 150px;
    }
    .shiftwork-trust-factors-v2-title{
        font-size: 48px;
        /* background: red; */
        width: 100%;
        padding-top: 0px;
        margin-left: -2.5px;
        padding-bottom: 25px;
    }
    .industries-info-container{
        flex-direction: column-reverse;
        height: 1000px;
        margin-top: 10px;
    }
    .industries-info-text{
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 20px;
        align-items: center;
    }
    .industries-info-text-title{
        font-size: 52.5px;
    }
    
    .industries-switch-holder{
        gap: 0px;
        height: 70px;
        border-radius: 20px;
        width: calc(95% - 10px);
        padding-left: 5px;
        padding-right: 5px;
    }
    .industries-switch{
        height: 60px;
        width: 57px;
        border-radius: 15px;
    }
    .industries-info-image{
        width: 100%;
        max-height: 500px;
        margin-bottom: 10px;
    }
    .industries-info-text-btn{
        width: 80%;
        margin-top: 20px;
    }
    .industries-title{
        font-size: 55px;
        padding-bottom: 30px;
        height: auto;
    }
    .shiftwork-trust-factors-v2-left-btn{
        margin: auto;
        margin-top: 25px;
        width: 80%;
    }

    .shiftwork-section-five{
        width: 95%;
        padding-left: 2.5%;
        padding-right: 2.5%;
        /* background: red; */
        height: auto;
        /* padding-top: 220px; */
        padding-bottom: 100px;
    }
    .shiftwork-section-five-quote {
        font-size: 25px;
        width: 100%;
    }
    .shiftwork-section-five-icon {
        height: 55px;
        width: 55px;
        font-size: 30px;
    }
    .shiftwork-about-mission-holder{
        padding-bottom: 25px;
    }

    .shiftwork-signup-switch-container{
        width: 90%;
    }

    .shiftwork-signup-switch{
        width:50%;
        font-size: 16px;
    }

    .shiftwork-signup-inner-container{
        width: calc(100% - 50px);
    }
    .shiftwork-signup-inner-right{
        padding: 0px;
    }
    .shiftwork-signup-inner-right-title{
        width: calc(100% - 50px);

        font-size: 30px;
        padding-top: 10px;

    }
    .shiftwork-signup-inner-right-subtitle{
        font-size: 20px;

    }
    .shiftwork-signup-title{

        font-size: 70px;
    }


    .shiftwork-sub{
        height: auto;
        width: 95%;
        padding-left: 2.5%;
        padding-right: 2.5%;
        padding-bottom: 50px;
        background: var(--shiftwork);
    }
    
    .shiftwork-sub-inner{
        height: auto;
        width: 100%;
        background: white;
        margin: auto;
        border-radius: 45px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
    }
    .shiftwork-sub-title{
        font-size: 36px;
        width: 100%;
        text-align: center;
    }
    .shiftwork-sub-subtitle{
        font-size: 15px;
        width: 90%;
        text-align: center;
    }
    .shiftwork-sub-input{
        width: 250px;
    }
    .shiftwork-sub-input-holder{
        flex-direction: column;
    }
    
}

/* iPad AIR (820px width) */
@media (min-width: 768px) and (max-width: 934px) {
    .shiftwork-about-mission-statement{
        font-size: 90px;
    }
    .shiftwork-sub{
        height: auto;
        width: 95%;
        padding-left: 2.5%;
        padding-right: 2.5%;
        padding-bottom: 50px;
        background: var(--shiftwork);
    }
    
    .shiftwork-sub-inner{
        height: auto;
        width: 100%;
        background: white;
        margin: auto;
        border-radius: 45px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
    }
    .shiftwork-sub-title{
        font-size: 42px;
        width: 100%;
        text-align: center;
    }
    .shiftwork-sub-subtitle{
        font-size: 15px;
        width: 100%;
        text-align: center;
    }
    .shiftwork-sub-input{
        width: 250px;
    }
    .shiftwork-text-logo-nav {
        font-size: 45px;  /* Adjust font size for 1024px */
        height: auto;     /* Adjust height proportionally */
        /* background: gray; */
        /* margin-bottom: -20px; */
    }
    .shiftwork-text-logo-nav.logo-scrolled {
        font-size: 45px; /* Shrink logo */
        height: auto;
        margin-top: 6px;
        /* background: red; */
        
    }
    .shiftwork-nav-menu{
        gap: 0px;
    }
    .shiftwork-nav-desktop {
        width: calc(100% - 20px);
        padding-left:10px;
        padding-right:10px;
    }
    .shiftwork-nav-subtitle {
        font-size: 15px;
    }
    .shiftwork-nav-title{
        padding-bottom: 0px;
    }
    .shiftwork-signup-inner-right-input-holder{
        height: auto;
    }
    .industries-switch-holder{
        width: calc(95% - 20px);
        padding-left: 10px;
        padding-right: 10px;
        gap: 10px;
    }
    .shiftwork-signup-inner-container{
        width: calc(95% - 50px);
        flex-direction: column;
        height: auto;
    }
    .industries-info-container{
        width: 95%;
    }
    .industries-info-text{
        width: 60%;
        padding-left: 0px;
    }
    .industries-info-text-text{
        width: calc(100% - 10px);
        padding-bottom: 22.5px;
    }
    .shiftwork-main-action-holder{
        width: 90%;
    }
    .shiftwork-main-action-btn{
        min-width: 100px;
    }

    .shiftwork-signup-inner-left{
        width: 100%;
        height: 250px;
    }
    .shiftwork-signup-inner-right{
        width: calc(95%);
        /* background: red; */
    }
    .shiftwork-section-five{
        width: calc(100% - 50px);
        padding-left: 25px;
        padding-right: 25px;
    }

    .shiftwork-trust-factors-v2-lower{
        flex-direction: column-reverse;
        height: 800px;
        /* background: red; */
    }
    .shiftwork-about-mission-bottom{
        flex-direction: column;
        /* background: red; */
    }
    .shiftwork-about-mission-card{
        width: 100%;
    }
    .shiftwork-about-mission-card-title{
        padding-top: 15px;
    }
    .shiftwork-trust-factors-v2-right {
        width: 100%;
    }
    .shiftwork-trust-factors-v2-left {
        width: 100%;
    }

    .shiftwork-faq-inner-container{
        width: 95%;
    }
    .shiftwork-about-mission-bottom{
        gap: 25px;
    }
    .shiftwork-faq-title{height: auto; font-style: italic;}
    .shiftwork-faq-inner-container-img{
        width: 30%;
    }
    .shiftwork-faq-inner-container-txt{
        width: calc(70% - 40px);
    }
    .shiftwork-faq-inner-container-txt-q{
        font-size: 32px;
    }
    .shiftwork-footer-bottom{
        flex-direction: column;
    }

    .shiftwork-footer-bottom-right{
        padding-bottom: 50px;
        min-width: 0px;
    }
    .shiftwork-footer-bottom-right-row{
        justify-content: center;
    }
    .shiftwork-about-holder{
        width: 95%;
    }

}


/* iPad PRO (1024px width) */
@media (min-width: 935px) and (max-width: 1169px) {
    .shiftwork-sub{
        height: auto;
        width: 95%;
        padding-left: 2.5%;
        padding-right: 2.5%;
        padding-bottom: 50px;
        background: var(--shiftwork);
    }
    
    .shiftwork-sub-inner{
        height: auto;
        width: 100%;
        background: white;
        margin: auto;
        border-radius: 45px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
    }
    .shiftwork-sub-title{
        font-size: 42px;
        width: 100%;
        text-align: center;
    }
    .shiftwork-sub-subtitle{
        font-size: 15px;
        width: 100%;
        text-align: center;
    }
    .shiftwork-sub-input{
        width: 250px;
    }
    .shiftwork-nav-menu{
        margin-top: -30px;
    }
    .shiftwork-text-logo-nav {
        font-size: 55px;  /* Adjust font size for 1024px */
        height: 55px;   
        /* background: gray; */
        /* margin-bottom: -20px; */
    }
    .shiftwork-text-logo-nav.logo-scrolled {
        font-size: 45px; /* Shrink logo */
        /* background: red; */
        /* padding-top: 15px; */
    }
    .industries-info-container{
        width: 95%;
    }

    .shiftwork-nav-subtitle {
        font-size: 18.5px;

    }

    .shiftwork-about-holder{
        width: 95%;
    }
    .shiftwork-trust-factors-v2{
        width: 95%;
        padding-left: 2.5%;
        padding-right: 2.5%;
    }

    .shiftwork-section-five{
        width: 95%;
        padding-left: 2.5%;
        padding-right: 2.5%;
    }

    .shiftwork-signup-inner-container{
        width: calc(95% - 20px);
    }
    .industries-info-text{
        padding-left: 25px;
        width: calc(60% - 25px);
    }

    .shiftwork-faq-inner-container{
        width: 95%;
    }
    .shiftwork-about-mission-bottom{
        gap: 25px;
    }

    .shiftwork-faq-inner-container-img{
        width: 35%;
    }
    .shiftwork-faq-inner-container-txt{
        width: calc(65% - 50px);
    }
    .shiftwork-footer-bottom{
        flex-direction: column;
    }

    .shiftwork-footer-bottom-right{
        padding-bottom: 50px;
    }
    .shiftwork-footer-bottom-right-row{
        justify-content: center;
    }
}




.testfooter{
    height: auto;
    width: 100%;
    /* background: red; */
    padding-top: 200px;
    padding-bottom: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.test-shiftwork {
    color: white;
    /* transform: scaleY(1.25); */
    font-size: 120px;
    font-style: italic;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 125, 'wght' 844;
  }


/* title */
/* 
    font-size: 120px;
    font-style: italic;
    font-family: "podium-sharp-variable",sans-serif;
    font-variation-settings: 'wdth' 125, 'wght' 844;
*/


/* Subtitle */
/* 
font-size: 35px;
font-style: italic;
font-family: "podium-sharp-variable",sans-serif;
font-variation-settings: 'wdth' 120, 'wght' 750;
*/